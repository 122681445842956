
import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/StringTool';
import PageFooter from '../component/PageFooter';
import PageFooterMobile from '../component/PageFooterMobile';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';
import * as StringTool from '../store/StringTool';
import $ from 'jquery';
import * as rdd from 'react-device-detect';


export default function DelayPosterPage(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [vHeight, setVHeight] = React.useState(window.innerHeight);
    let resizeTimer;

    React.useEffect(() => {
        $(window).on("resize", changeSize);
    }, []);

    function changeSize(e) {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            setVHeight(window.innerHeight)
        }, 250);
    }

    function isMobile() {
        return rdd.isMobile || window.innerWidth < 700;
    }

    return (
        <Stack sx={{
            bgcolor: 'black',
            width: 1,
            height: vHeight,
            minHeight: window.innerHeight,
            alignItems: 'center',
            justifyContent: !isMobile() && 'center',
            backgroundSize: '100% 100%',
            backgroundImage: `url(${settingData.isMobile ? getImgPath('mobile/part4.jpg') : getImgPath('part8.jpg')})`,
            overflow: 'scroll',
        }}>
            <Box
                component={'div'}
                sx={{
                    color: 'white',
                    px: 2,
                    py: 3,
                }}
                dangerouslySetInnerHTML={{
                    __html:
                        `
                        <h1>스트리트 파이터: 듀얼 일정 안내</h1>
                        <p>안녕하세요. 더 강한 사람은 늘 있기 마련!&nbsp;<br />액션 카드 RPG 게임, 스트리트 파이터: 듀얼입니다.​</p>
                        <p>심사 과정의 지연으로 정식출시가 연기된 스트리트 파이터: 듀얼이<br />시행착오 끝에 마침내 정식출시 일정을 여러분께 안내드릴 수 있게 되었습니다.</p>
                        <p>기다려 주신 격투가님께 진심으로 감사드리며,<br />자세한 내용은 아래에서 확인해 주시기를 바랍니다.</p>
                        <p><strong>스트리트 파이터: 듀얼 일정 안내</strong><br /><strong>- 사전 다운로드 시간: 2024년 4월 3일(수) 21:00</strong><br /><strong>- 정식출시 시간: 2024년 4월 4일(목) 12:00</strong></p>
                        <p><strong>4월 3일 21:00</strong>부터 스트리트 파이터: 듀얼을 다운로드 받으실 수 있으며&nbsp;<br />사전 다운로드 완료 시, 게임 정식출시 후 보다 빠르게 게임을 이용하실 수 있습니다.</p>
                        <p>감사합니다.</p>
                            `
                }} />

        </Stack>
    )
}