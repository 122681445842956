import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/StringTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';
import * as StringTool from '../store/StringTool';
import PaymentButton from '../component/PaymentButton';

export default function HomePage() {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function playTap() {
        dispatch(settingActions.showVideoAlert(true));
    }

    return (
        <Stack sx={{
            bgcolor: 'black',
            width: 1,
            minHeight: window.innerHeight,
            WebkitFilter: settingData.showVideoAlert ? 'brightness(0%)' : 'brightness(100%)',
            filter: settingData.showVideoAlert ? 'brightness(0%)' : 'brightness(100%)',
        }}>
            <Box
                component={'video'}
                autoPlay={true}
                loop={true}
                muted={true}
                playsInline={true}
                disablePictureInPicture={true}
                poster={getImgPath('vbg.jpg')}
                src={settingData.region == 'jp' ? getImgPath('video_jp.mp4') : (getImgPath('video_all.mp4'))}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                    zIndex: 1,
                }} />

            <Box
                component={'img'}
                src={getImgPath('index_mask.png')}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    objectFit: 'fill',
                    zIndex: 2,
                }} />

            {/* 控件 */}
            <Stack sx={{
                position: 'absolute',
                width: 1,
                height: 1,
                zIndex: 3,
                justifyContent: 'center',
                alignItems: 'center',
                pt: 6,
            }}>

                <Box
                    component={'img'}
                    src={getImgPath(`slogan_${settingData.region}.png`)}
                    sx={{
                        height: '220px',
                    }} />

                <Box
                    onClick={playTap}
                    component={'img'}
                    src={getImgPath('play.png')}
                    sx={{
                        height: '50px',
                        cursor: 'pointer',
                        transitionDuration: '0.3s',
                        '&:hover': {
                            transform: 'scale(1.1)',
                        },
                    }} />

                <Stack sx={{
                    flexDirection: 'row',
                    mt: 3,
                }}>
                    <Typography sx={{
                        fontSize: 40,
                        fontWeight: 'bold',
                        color: 'white',
                        fontFamily: 'MyFont',
                    }}>
                        {settingData.langDic['home1']}
                    </Typography>

                    <Typography sx={{
                        fontSize: 40,
                        fontWeight: 'bold',
                        color: 'red',
                        fontFamily: 'MyFont',
                        ml: 1,
                    }}>
                        {/* toUpperCase */}
                        {settingData.langDic['home2'].toUpperCase()}
                    </Typography>
                </Stack>

                <Typography sx={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    fontFamily: 'MyFont',
                    color: 'white',
                    display: 'none'
                }}>
                    {settingData.langDic['home3']}
                </Typography>

                {/* 下载按钮 */}
                <Stack sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    mt: 3,
                }}>

                    <Box
                        component={'img'}
                        src={getImgPath('red_left.png')}
                        sx={{
                            height: '90px',
                            mr: 2,
                        }} />

                    <PaymentButton />

                    <Box
                        onClick={() => {
                            StringTool.appleTap();
                        }}
                        component={'img'}
                        src={getImgPath(`btns/${settingData.region}_apple_release.png`)}
                        sx={{
                            height: '55px',
                            mr: 1,
                            borderColor: '#cccccc',
                            borderRadius: '6px',
                            cursor: 'pointer',
                            transitionDuration: '0.3s',
                            '&:hover': {
                                transform: 'scale(1.1)',
                            },
                        }} />

                    <Box
                        onClick={() => {
                            StringTool.googleTap();
                        }}
                        component={'img'}
                        src={getImgPath(`btns/${settingData.region}_google_release.png`)}
                        sx={{
                            height: '55px',
                            ml: 1,
                            borderColor: '#cccccc',
                            borderRadius: '6px',
                            cursor: 'pointer',
                            transitionDuration: '0.3s',
                            '&:hover': {
                                transform: 'scale(1.1)',
                            },
                        }} />

                    <Box
                        onClick={() => {
                            StringTool.onestoreTap();
                        }}
                        component={'img'}
                        src={getImgPath(`btns/${settingData.region}_one_release.png`)}
                        sx={{
                            display: settingData.region == 'kr' ? 'flex' : 'none',
                            height: '55px',
                            ml: 2,
                            borderColor: '#cccccc',
                            borderRadius: '6px',
                            cursor: 'pointer',
                            transitionDuration: '0.3s',
                            '&:hover': {
                                transform: 'scale(1.1)',
                            },
                        }} />

                    <Box
                        component={'img'}
                        src={getImgPath('red_right.png')}
                        sx={{
                            height: '90px',
                            ml: 2,
                        }} />
                </Stack>

                <Box component={'img'}
                    src={getImgPath('next.png')}
                    sx={{
                        mt: 3,
                        height: '22px',
                        animation: 'scrollerAni 1s ease-in-out both infinite',
                    }} />

                {/* 延迟公告 */}
                <Typography
                    onClick={() => {
                        window.open(window.location + 'announcement');
                    }}
                    sx={{
                        display: 'none',
                        zIndex: 20,
                        position: 'absolute',
                        top: '100px',
                        color: 'red',
                        fontSize: 20,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        bgcolor: '#000000cc',
                        px: 3,
                        py: 1,
                    }}>
                    스트리트 파이터: 듀얼 일정 안내
                </Typography>


            </Stack>
        </Stack>
    )
}