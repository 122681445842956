import ReactDOM from 'react-dom/client';
import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import AppMobile from './AppMobile';
import store from './store/Store';
import { Provider } from 'react-redux';
import VideoAlert from './component/VideoAlert';
import * as rdd from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from './store/SettingSlice';
import { getImgPath } from './store/StringTool';
import FighttingPage from './page/FighttingPage';
import axios from 'axios';
import * as StringTool from './store/StringTool';
import PrivacyPage from './page/PrivacyPage';
import TermsPage from './page/TermsPage';
import TermsJP from './page/TermsJP';
import GameVideo from './component/GameVideo';
import SubmitEmailAlert from './component/SubmitEmailAlert';
import LoadingAndHint from './component/LoadingAndHint';
import DelayPosterPage from './page/DelayPosterPage';
import GiftCodePage from './page/GiftCodePage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<AppWraper />} />
        <Route path='/fightting' element={<FighttingPage />} />
        <Route path='/privacy' element={<PrivacyPage />} />
        <Route path='/terms' element={<TermsPage />} />
        <Route path='/game_video' element={<GameVideo />} />
        <Route path='/game_video_kr' element={<GameVideo />} />
        <Route path='/financial' element={<TermsJP contentFlag={1}/>} />
        <Route path='/special' element={<TermsJP contentFlag={2}/>} />
        <Route path='/announcement' element={<DelayPosterPage/>} />
        <Route path='/gift_code' element={<GiftCodePage />} />
        
      </Routes>
      <VideoAlert />
      <SubmitEmailAlert/>
      <LoadingAndHint/>
    </BrowserRouter>
  </Provider>
);


function AppWraper() {
  const dispatch = useDispatch();
  const [flag, setFlag] = React.useState(0);

  React.useEffect(() => {
    dispatch(settingActions.setIsMobile(isMobile()));
    getIpInfo((isPass) => {
      if (isPass) {
        setFlag(1);
      } else {
        setFlag(-1);
      }
    })
  }, []);

  function isMobile() {
    return rdd.isMobile || window.innerWidth < 700;
  }

  return (
    <Stack>
      {flag == 1 &&
        <Stack>
          {isMobile() && <AppMobile />}
          {!isMobile() && <App />}
        </Stack>
      }

      {flag == -1 && <ForbiddenView />}

    </Stack>
  )
}

function ForbiddenView() {
  const settingData = useSelector(state => state.settingData);

  function isMobile() {
    return rdd.isMobile || window.innerWidth < 700;
  }

  return (
    <Stack sx={{
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      width: 1,
      height: '100vh',
      backgroundSize: '100% 100%',
      backgroundImage: `url(${isMobile() ? getImgPath('notavailable-m.jpg') : getImgPath('notavailable.jpg')})`,
    }}>

      <Typography sx={{
        fontSize: settingData.isMobile ? 20 : 40,
        mt: 1,
      }}>
        SORRY
      </Typography>

      <Typography sx={{
        fontSize: settingData.isMobile ? 14 : 30,
        mt: 1,
      }}>
        THIS PAGE IS NOT AVAILABLE IN YOUR REGION.
      </Typography>

    </Stack>

  );
}


//get ip请求
function getIpInfo(onResult) {
  const globalCode = ["DZ", "AE", "OM", "AZ", "EG", "AO", "BH", "BJ", "BW", "BF", "CV", "GM",
    "CG", "GW", "GH", "ZW", "QA", "KW", "KE", "LB", "LR", "MG", "MW", "ML", "MU", "MR", "MZ",
    "NA", "ZA", "NE", "NG", "SL", "SN", "SC", "SA", "ST", "SZ", "TZ", "TN", "UG", "AM", "YE",
    "IL", "IN", "JO", "TD", "AR", "PY", "PA", "BR", "PE", "BO", "BZ", "EC", "CO", "CR", "GY",
    "HN", "MX", "NI", "SV", "SR", "GT", "VE", "UY", "CL", "AL", "EE", "AT", "BY", "BG", "MK",
    "BE", "PL", "DE", "RU", "FR", "KZ", "NL", "KG", "CZ", "HR", "LV", "LT", "LU", "RO", "MT",
    "MD", "NO", "PT", "CH", "CY", "SK", "SI", "TJ", "TR", "TM", "UA", "UZ", "ES", "GR", "HU",
    "IT", "AF", "BA", "CD", "GE", "ME", "GA", "CM", "CI", "LY", "RW", "MV", "MA", "RS", "IQ",
    "ZM", "PK", "BT", "NP", "LK", "IR", "GN", "LI", "ER", "DJ", "SM", "TG", "BD", "MC", "KM",
    "SO", "SD"];
  const seaCode = ["SG", "MY", "TH", "ID", "PH"];
  const krCode = ["KR"];
  const jpCode = ["JP"];
  const allowedIPList = ["23.249.24.80","182.255.32.10","118.242.32.210","103.116.72.6"];

  function isPass(ary, code) {
    var ret = false;
    for (var i = 0; i < ary.length; i++) {
      if (ary[i].toUpperCase() == code.toUpperCase()) {
        ret = true;
        break;
      }
    }
    return ret;
  }

  axios.get(StringTool.getAPIHost() + '/1/geo/ip', {
    headers: {},
  }).then(({ data }) => {
    const region = StringTool.getRegion();
    if (0 == data.code) { //成功
      const ip = data.data.ip;
      const countryCode = data.data.countryCode;

      if (isPass(allowedIPList, ip)) { //白名单
        onResult(true);

      } else if (isPass(jpCode, countryCode)) { //日本ip
        if (region == 'jp') { //日本域名
          onResult(true); //打开
        } else {
          window.location.href = "https://jp.sfduelmobile.com";
        }

      } else if (isPass(krCode, countryCode)) { //韩国ip
        if (region == 'kr') { //韩国域名
          onResult(true); //打开
        } else {
          window.location.href = "https://kr.sfduelmobile.com";
        }

      } else if (isPass(seaCode, countryCode)) { //东南亚ip
        if (region == 'en') { //东南亚域名
          onResult(true); //打开
        } else {
          window.location.href = "https://sea.sfduelmobile.com";
        }

      } else if (isPass(globalCode, countryCode)) { //欧洲
        window.location.href = "https://www.sfduelmobile.com";
      } else {
        onResult(false);
      }

    } else {
      onResult(false);
    }
  }).catch(function (error) {
    console.log(error);
    onResult(false);
  });
}